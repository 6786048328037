<!--
 * @Description: 新增、修改费用类型
 * @Author: 琢磨先生
 * @Date: 2022-05-18 23:17:14
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-20 01:05:20
-->

<template> 
  <el-dialog
    v-model="drawer"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="title"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="房东占比(%)" prop="scale">
        <el-col :span="10">
          <el-input v-model="form.scale" placeholder="请输入百分比"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item label="描述" prop="description">
        <el-col :span="20">
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder="描述内容"
          ></el-input
        ></el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button
        type="primary"
        @click="onSubmit"
        :disabled="loading"
        :loading="loading"
        >确定</el-button
      >
      <el-button @click="cancelClick" :disabled="loading">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      title: "编辑",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        scale: [
          {
            required: true,
            message: "请输入房东占比",
            trigger: "blur",
          },
          {
            type: "string",
            pattern:  /^(100|[1-9]?[0-9](\.[0-9]{0,2})?)$/,
            message: "百分比格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = this.item;
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/seller/v1/expense/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 规格选择变动
     */
    sizeChange(val) {
      console.log(val);
    },
  },
};
</script>

